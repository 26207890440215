import React from "react";
import Layout from '../../components/Layout/Layout';
import Seo from "../../components/Seo/Seo";
import { withPrefix } from 'gatsby';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import UtilBranLock from '@economist/fabric-components/utility/utilbranlock/UtilBranLock';
import ScrollDepth from '@economist/fabric-components/utility/ScrollDepthTracker/ScrollDepth';
import UtilWebVitals from '@economist/fabric-components/utility/utilwebvitals/UtilWebVitals';
/*  Data imports    */
import pageData from '../../constants/pages/briefing-paper';

import CardSectionSec from "../../components/Sections/CardSectionSec/CardSectionSec";
import VideoSection from '../../components/Sections/VideoSection/VideoSection';
import HeroSecondSection from '../../components/Sections/HeroSecondSection/HeroSecondSection';
import DownloadSection from '../../components/Sections/DownloadSection/DownloadSection';
import MessageWithImgSection from '../../components/Sections/MessageWithImgSection/MessageWithImgSection';
import config from '../../config/config';
import { Page } from '../../components/Page';

const IndexPage = () => {
	const windowWidth = useWindowWidth();

	const heroSecondSection = pageData.heroSecondSection
		? <HeroSecondSection
			authorComponent={
				<UtilBranLock
					alt="written by logo"
					text="written by"
					logo={withPrefix('/images/svg/EIUSVG.svg')
					}
				/>}
			position={pageData.heroSecondSection.position}
			data={pageData.heroSecondSection} />
		: null;

	const cardSectionOne = pageData.cardSectionOne
		? <CardSectionSec position={pageData.cardSectionOne.position || 'left'} data={pageData.cardSectionOne} />
		: null;

	const videoSectionOne = pageData.videoSectionOne
		? <VideoSection data={pageData.videoSectionOne} />
		: null;

	const cardSectionTwo = pageData.cardSectionTwo
		? <CardSectionSec position={pageData.cardSectionTwo.position || 'left'} data={pageData.cardSectionTwo} />
		: null;

	const cardSectionThree = pageData.cardSectionThree
		? <CardSectionSec position={pageData.cardSectionThree.position || 'left'} data={pageData.cardSectionThree} />
		: null;

	const messageWithImg = pageData.messageWithImg
		? <MessageWithImgSection addBackgroundCard={pageData.addBackgroundCard || false} data={pageData.messageWithImg} />
		: null;

	const downloadSectionOne = pageData.downloadSectionOne
		? <DownloadSection data={pageData.downloadSectionOne} />
		: null;

	const cardSectionFour = pageData.cardSectionFour
		? <CardSectionSec
			position={pageData.cardSectionFour.position || 'left'}
			data={pageData.cardSectionFour}
			indentBottom={false}
			smallBg={true}
			authorComponent={
				<UtilBranLock
					alt="an infographic by"
					text="an infographic by"
					alignMobile="center"
					logo={withPrefix('/images/svg/EIUSVG.svg')
					} />
			}
		/>
		: null;

	return (
		<>
			<Seo title={pageData.pageTitle} description={pageData.pageDescription} />
			<Page>
				<Layout invertMenu={windowWidth <= config.mobileWidth}
					menuIndentDesktop={70}
					menuChild={windowWidth <= config.mobileWidth ? heroSecondSection : null}>


					<UtilWebVitals />
					<ScrollDepth />

					<div style={{ maxWidth: "1440px", margin: "0 auto" }}>

						{windowWidth > config.mobileWidth ? heroSecondSection : null}

						{cardSectionOne}

						{videoSectionOne}

						{cardSectionTwo}

						{messageWithImg}

						{cardSectionThree}

						{downloadSectionOne}

						{cardSectionFour}

					</div>
				</Layout>
			</Page>
		</>
	)
};

export default IndexPage
