import React, {useEffect} from 'react';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import classes from './DownloadSection.module.scss';
import LinkButtonCircular from '../../UI/ButtonCircular/LinkButtonCircular';
import PropTypes from 'prop-types';
import {withPrefix} from "gatsby";
import LinkDropdownCircular from "../../UI/LinkDropdownCircular/LinkDropdownCircular";

const DownloadSection = ({
     data: {
         content,
			   files,
         subcontent
     }
 }) => {
	const sectionAnim = useAnimation();
	const contentAnim = useAnimation();
	const subcontentAnim = useAnimation();
	const linkBtnAnim = useAnimation();

	const [ref, inView] = useInView({
		threshold: .1,
		triggerOnce: true,
		rootMargin: '320px'
	});
	useEffect(() => {
		if (inView) {
			sectionAnim.start('visible');
			contentAnim.start('visible');
			subcontentAnim.start('visible');
			linkBtnAnim.start('visible');
		}
	}, [sectionAnim, inView]);

	return (
		<>
			<div ref={ref}>
				<motion.div
					animate={sectionAnim}
					initial="hidden"
					variants={{
						visible: {opacity: 1, y: 0},
						hidden: {opacity: 0, y: '500px'}
					}}
					transition={{type: 'spring', stiffness: 40}}
					className={classes.section}
				>

					<motion.div
						animate={contentAnim}
						initial="hidden"
						variants={{
							visible: {opacity: 1, x: 0},
							hidden: {opacity: 0, x: '-500px'}
						}}
						transition={{type: 'spring', stiffness: 40, delay: .2}}
						className={classes.content}
						dangerouslySetInnerHTML={{__html: content}}/>

					{
						files ? <motion.div
								animate={contentAnim}
								initial="hidden"
								variants={{
									visible: {opacity: 1, x: 0},
									hidden: {opacity: 0, x: '500px'}
								}}
								transition={{type: 'spring', stiffness: 40, delay: .2}}
								className={classes.content}>
								{files ? <LinkDropdownCircular
									file={files}
									targetBlank={true}
									iconDirection={'down'}
								>
									{files.label_main}
								</LinkDropdownCircular> : null}
							</motion.div>
							: null
					}

					{
						subcontent ?
							<motion.div
								animate={subcontentAnim}
								initial="hidden"
								variants={{
									visible: {opacity: 1, y: 0},
									hidden: {opacity: 0, y: '500px'}
								}}
								transition={{type: 'spring', stiffness: 40, delay: .4}}
								dangerouslySetInnerHTML={{__html: subcontent}}
								className={classes.subcontent}
							/>
							: null
					}

				</motion.div>
			</div>
		</>
	)
};

DownloadSection.propTypes = {
	data: PropTypes.shape({
		content: PropTypes.string.isRequired,
		files: PropTypes.object.isRequired,
		subcontent: PropTypes.string,
	}).isRequired,
};

export default DownloadSection;
