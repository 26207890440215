import React, {useEffect, useRef, useState} from 'react';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {withPrefix} from 'gatsby';
import classes from './VideoSection.module.scss';
import {ReactSVG} from 'react-svg';
import PropTypes from 'prop-types';

const VideoSection = ({data: {content}}) => {
	const sectionAnim = useAnimation();
	const videoAnim = useAnimation();
	const contentAnim = useAnimation();


	const [sectionRef, inView] = useInView({
		threshold: .3,
		triggerOnce: true,
		rootMargin: '100px'
	});

	useEffect(() => {
		if (inView) {
			sectionAnim.start('visible');
			videoAnim.start('visible');
			contentAnim.start('visible');
		}
	}, [sectionAnim, inView]);

	return (
		<>
			<div ref={sectionRef}>
				<motion.div
					animate={sectionAnim}
					initial="hidden"
					variants={{
						visible: {opacity: 1, y: 0},
						hidden: {opacity: 0, y: '500px'}
					}}
					transition={{type: 'spring', stiffness: 40}}
					className={classes.section}
				>

					<div className={classes.contentWrapper}>

						<motion.div
							animate={videoAnim}
							initial="hidden"
							variants={{
								visible: {opacity: 1, x: 0, y: 0},
								hidden: {opacity: 0, x: '-500px', y: '-100px'}
							}}
							transition={{type: 'spring', stiffness: 30, delay: .2}}
							className={classes.videoWrapper}
						>

							{/*<div className={classes.text}>
								Despite the unprecedented disruption, many businesses and sectors will benefit from the acceleration of existing trends. Cybersecurity, Financial Technology (FinTech), Education Technology (EdTech), and Smart Cities are among the industries that have the potential to drive the recovery and future growth.
							</div>*/}

							<div className={classes.videoArea}>
								<iframe
									src="https://players.brightcove.net/1153191510001/default_default/index.html?videoId=6237179594001"
									allowFullScreen="" allow="encrypted-media" width="960" height="540">
									This video is not supported by this browser
								</iframe>
							</div>
						</motion.div>

						<motion.div
							animate={contentAnim}
							initial="hidden"
							variants={{
								visible: {opacity: 1, x: 0},
								hidden: {opacity: 0, x: '500px'}
							}}
							transition={{type: 'spring', stiffness: 30, delay: .4}}
							dangerouslySetInnerHTML={{__html: content}}
						/>

					</div>

				</motion.div>
			</div>
		</>
	)
};

VideoSection.propTypes = {
	data: PropTypes.shape({
		content: PropTypes.string
	}).isRequired
};

export default VideoSection;
